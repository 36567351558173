@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
.custom-dropdown .Dropdown-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #D1D5DB; /* Tailwind border-gray-300 */
    background-color: white;
    position: relative;
}

.custom-dropdown .Dropdown-arrow {
    position: absolute;
    right: 10px; /* Adjust this value to align the arrow */
    top: 50%;
    transform: translateY(-50%); /* Centers the arrow vertically */
    pointer-events: none;
}

.custom-dropdown .Dropdown-menu {
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    background-color: white;
}

.custom-dropdown .Dropdown-placeholder {
    color: #888; /* Placeholder color */
    font-weight: 400;
}

.custom-dropdown .Dropdown-option.is-selected {
    color: black; /* Ensure selected option color is black */
}

.custom-dropdown .Dropdown-option {
    color: black; /* Set the color of the dropdown options */
}

.custom-dropdown .Dropdown-control .Dropdown-placeholder.is-selected {
    color: black; /* Ensures the selected option in control is black */
}
